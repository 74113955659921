import axios from 'axios'
import AxiosWrapper from './axios'

const authAxios = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_AUTH_API + '/',
    crossDomain: true
})

// Logs any errors to the console when in development.
authAxios.interceptors.response.use(
    res => res,
    err => {
        return err
    }
)

export default new AxiosWrapper(authAxios);
