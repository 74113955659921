import jwtDecode from 'jwt-decode';
import store from '../store';
import { FETCH_USER_INFO, LOGOUT_USER } from '../store/types/actions';

import authApiService from './auth-api.service';

class AuthService {
    static accessTokenLocalStorageKey = 'access_token';
    static refreshTokenLocalStorageKey = 'refresh_token';

    accessToken;
    expiresAt;
    refreshToken;

    accessTokenExpiringTimeout;
    accessTokenExpiredTimeout;

    async storeAuthentication(
        accessToken,
        tokenExpiration,
        refreshToken
    ) {
        window.localStorage.setItem(AuthService.accessTokenLocalStorageKey, accessToken);
        window.localStorage.setItem(AuthService.refreshTokenLocalStorageKey, refreshToken);

        if (this.accessTokenExpiringTimeout) clearTimeout(this.accessTokenExpiringTimeout);
        this.accessTokenExpiringTimeout = setTimeout(this.onAccessTokenExpiring, tokenExpiration - Date.now() - 60000);
        if (this.accessTokenExpiredTimeout) clearTimeout(this.accessTokenExpiredTimeout);
        this.accessTokenExpiredTimeout = setTimeout(this.onAccessTokenExpired, tokenExpiration - Date.now());

        this.accessToken = accessToken;
        this.expiration = tokenExpiration;
        this.refreshToken = refreshToken;

        await store.dispatch('user/' + FETCH_USER_INFO);
    }

    async onAccessTokenExpiring() {
        try {
            let accessTokenResponse = await this.refreshAccessToken(this.refreshToken);
            let decodedAccessToken = jwtDecode(accessTokenResponse.accessToken);
            const tokenExpiration = decodedAccessToken.exp * 1000;

            if (tokenExpiration > Date.now()) {
                await this.storeAuthentication(accessTokenResponse.accessToken, tokenExpiration, accessTokenResponse.refreshToken)
            }
        } catch (error) {
            // Failed
        }
    }

    onAccessTokenExpired() {
        this.accessToken = undefined;
        this.expiration = undefined;
        this.refreshToken = undefined;

        store.dispatch('user/' + LOGOUT_USER);
    }

    async refreshAccessToken(refreshToken) {
        try {
            let response = await authService.post('token/refresh?refresh-token=' + refreshToken);
            return response.data;
        } catch {
            return undefined;
        }
    }

    async revokeAccessToken(refreshToken) {
        try {
            await authService.post('token/revoke?refresh-token=' + refreshToken);
        } catch {

        }
    }

    async logout() {
        if (this.refreshToken) {
            await this.revokeRefreshToken(this.refreshToken);
        }

        if (this.accessTokenExpiringTimeout) clearTimeout(this.accessTokenExpiringTimeout);
        if (this.accessTokenExpiredTimeout) clearTimeout(this.accessTokenExpiredTimeout);
        this.accessToken = undefined;
        this.refreshToken = undefined;
        this.expiration = undefined;

        window.localStorage.removeItem(AuthService.accessTokenLocalStorageKey);
        window.localStorage.removeItem(AuthService.refreshTokenLocalStorageKey);
    }

    async revokeRefreshToken(refreshToken) {
        try {
            await authService.post('token/revoke?refresh-token=' + refreshToken);
        } catch {

        }
    }

    linkDiscord(returnUrl, errorUrl) {
        returnUrl = returnUrl ?? window.location.origin + '/link-accounts';
        errorUrl = errorUrl ?? (window.location.origin + '/error');

        const processUrl = process.env.VUE_APP_AUTH_API + "/discord/link/process?callbackUrl=" + encodeURIComponent(returnUrl) + "&errorUrl=" + encodeURIComponent(errorUrl)
        return authApiService.get("discord/link")
            .then((res) => { window.location = processUrl + "&code=" + encodeURIComponent(res.data.data.code); });
    }

    unlinkDiscord() {
        return authApiService.get('discord/unlink');
    }

    async obtainInitialTokens(code) {
        try {
            let response = await authApiService.post('/token?code=' + code);
            return response.data.data;
        } catch (error) {
            return undefined;
        }
    }

    async initialize() {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');

        if (authCode) {
            urlParams.delete('code');
            window.history.pushState(null, document.title, window.location.origin);

            const token = await this.obtainInitialTokens(authCode);

            if (!token) return;



            const decodedAccessToken = jwtDecode(token.accessToken);
            await this.storeAuthentication(token.accessToken, decodedAccessToken.exp * 1000, token.refreshToken);

            return;
        }

        let accessToken = window.localStorage.getItem(AuthService.accessTokenLocalStorageKey);
        let refreshToken = window.localStorage.getItem(AuthService.refreshTokenLocalStorageKey);

        if (!refreshToken) return;

        if (!!accessToken) {
            try {
                let decodedAccessToken = jwtDecode(accessToken);
                const tokenExpiration = decodedAccessToken.exp * 1000;

                if (tokenExpiration > Date.now()) {
                    await this.storeAuthentication(accessToken, tokenExpiration, refreshToken);
                    return;
                }
            } catch {

            }
        }

        try {
            let accessTokenResponse = await this.refreshAccessToken(refreshToken);
            let decodedAccessToken = jwtDecode(accessTokenResponse.accessToken);
            const tokenExpiration = decodedAccessToken.exp * 1000;

            if (tokenExpiration > Date.now()) {
                await this.storeAuthentication(accessTokenResponse.accessToken, tokenExpiration, accessTokenResponse.refreshToken);
                return;
            }
        } catch {

        }
    }
}

export default new AuthService();