<template lang="pug">
div
  landing-hero

  landing-video-text.mt-48(youtube="u0oMI2Z6mpc")
    h1.text-5xl.text-white.feature-font Vital 10x
    p.text-primary-400.mt-6
      | With our recent release of our 10x servers we have seen an insane 1600 rocket raid by ZERK clan!
      | <br />
      | <br />
      | Our 10x server is a very unique server in the modded community as we grant everyone access to the quality of life plugins like BGrade, Furnace Splitter, Auto Doors and SkinBox for free!
      | <br />
      | <br />
      | Our objective with the 10x servers is to create the perfect server that doesn't promote endless pay to win... unlike others...
    h2.text-lg.mt-8.mb-4.text-white.feature-font Connect Here
    app-button(icon="fab steam", href="steam://connect/us-10x.vitalrust.com:28035", redirect) US 10x
    app-button.ml-4(icon="fab steam", href="steam://connect/eu-10x.vitalrust.com:28035", redirect) EU 10x
    app-button.ml-4(icon="fab steam", href="steam://connect/au-10x.vitalrust.com:28035", redirect) AU 10x

  landing-VIP.mt-48

  landing-video-text.mt-48(flip)(youtube="y5Xbx3DAkM0", :start="18")
    h1.text-5xl.text-white.feature-font Unwritten's Raid Defense
    p.text-primary-400.mt-6 This video perfectly captures what Vital is all about. Large raids, large amounts of action, and putting it all out on the line to defend your base and everything in it against all odds.
    .mt-8
    app-button(icon="fab youtube", href="https://www.youtube.com/channel/UCA6aUAFP0MXdablYfNcK9-A", redirect) Unwritten's YouTube Channel

  .bg-dark-600.mt-48.py-24
    landing-video-text.py-24(youtube="0UOiC8Fr0FQ", :start="1946")
      h1.text-5xl.text-white.feature-font Dinling and his vital progression
      p.text-primary-400.mt-6 Dinling is a leader of the clan "OT" on Vital Main - He is also a community member and content creator. We choose exemplary members of our community whom showcase and capture exactly what Vital Rust is all about.
      .mt-8
      app-button(icon="fab youtube", href="https://www.youtube.com/channel/UCxd1fcnzQRtrHQFfchCDtVQ", redirect) Dinling's YouTube Channel

  .container.mx-auto.px-2.py-24
    h1.text-5xl.feature-font.text-white Our Partners
    carousel.mt-12(:per-page="carouselPerPage", autoplay, autoplayHoverPause, pagination-active-color="#F9D56A", pagination-color="#141414")
      slide.flex.justify-center.align-center.max-w-5xl(v-for="(happen, index) in happening", :key="index")
        a.flex(:href="happen.url", target="_blank")
          img.object-contain.rounded.max-h-48(class='lg:w-60' :src="happen.image", :width="happen.width", height="auto")
</template>

<script>
import LandingHero from "../components/Landing/Hero";
import LandingVideoText from "../components/Landing/VideoText";
import LandingVIP from "../components/Landing/VIP";

import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    LandingHero,
    LandingVideoText,
    LandingVIP,
    Carousel,
    Slide,
  },
  data() {
    return {
      carouselPerPage: 2,
      happening: [
        { image: require("@/assets/gsk-logo.svg"), width: "80%", url: "https://www.gameserverkings.com/games/rust-server-hosting" },
        { image: require("@/assets/cosmic-logo.svg"), width: "80%", url: "https://cosmicguard.com" },
      ],
    };
  },
  methods: {
    onWindowResized(e) {
      if (e.target.innerWidth < 640) this.carouselPerPage = 1;
      else this.carouselPerPage = 2;
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.onWindowResized);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResized);
  },
};
</script>