import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './modules/user.module';

const store = new Vuex.Store({
    state: {
        steamAuthLink: `${process.env.VUE_APP_BACKEND}/login?callbackUrl=${process.env.VUE_APP_FRONTEND}&errorUrl=${process.env.VUE_APP_FRONTEND}`,
    },
    mutations: { }, actions: { },
    modules: {
        user
    }
});

/**
 * @desc Promise that resolves true when authenticated and false when unauthenticated
 */
export let authPromise = new Promise( resolve => {
    store.watch(
        ( state, getters ) => getters['user/loggedIn'],
        newValue => {
            // Resolve, authentication has been checked
            resolve( newValue );
        }
    )
} );

export default store;