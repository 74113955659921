import '@/assets/index.scss';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import authService from './services/auth.service';

import './common/icons';

// Global components
import { Button, Icon } from './components/App';
Vue.component('app-button', Button);
Vue.component('app-icon', Icon);

Vue.config.productionTip = false

const VueApp = new Vue({
    router,
    store,
    render: h => h(App),
    async mounted() {
        //store.dispatch( 'user/' + FETCH_USER_INFO );
        await authService.initialize();
        store.state.user.authed = store.state.user.info.username ? true : false;
    }
}).$mount('#app')

export default VueApp;