import userService from '../../services/user.service';
import authService from '../../services/auth.service';

import {
    FETCH_USER_INFO,
    LOGOUT_USER,
    UNLINK_DISCORD,
    LINK_DISCORD
} from '../types/actions';

import {
    SET_USER
} from '../types/mutations';

const defaultInfoState = () => {
    return {
        username: "",
        steamId: "",
        discordId: "",
        discordName: "",
        avatar: ""
    }
}

const state = {
    authed: undefined,          // undefined = not checked, false = checked and not authed, true = checked and authed
    info: defaultInfoState()
}

const getters = {
    authChecked: state => state.authed !== undefined,
    loggedIn: state => state.authed,
    userInfo: state => state.authed ? state.info : false
}

const actions = {
    async [FETCH_USER_INFO]({ commit }) {
        let user = await userService.fetchUserInfo();
        commit(SET_USER, user);
    },
    async [LOGOUT_USER]({ commit }) {
        await authService.logout();
        commit(SET_USER, false);
    },
    [UNLINK_DISCORD]({ commit }) {
        authService.unlinkDiscord();
        commit(SET_USER, { discordId: "" });
    },
    [LINK_DISCORD]() {
        authService.linkDiscord();
    }
}

const mutations = {
    [SET_USER](state, user) {
        if( !user ) {
            Object.assign( state.info, defaultInfoState() ); // reset state
            state.authed = false;
            return
        }

        state.authed = true;
        Object.assign( state.info, user );
    }
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}