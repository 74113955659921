import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import store, { authPromise } from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: { title: 'Account', requiresAuth: true }
    },
    {
        path: '/link-accounts',
        name: 'Link Accounts',
        component: () => import('../views/AccountLink.vue'),
        meta: { title: 'Link Account' }
    },
    {
        path: '/stats',
        name: 'Stats',
        component: () => import('../views/Stats.vue'),
        meta: { title: 'Stats' }
    },
    {
        path: '*',
        redirect: '/'
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Register page titles etc.

router.beforeEach( async (to, from, next) => {
    document.title = to.meta.title ? 'Vital Rust — ' + to.meta.title : 'Vital Rust';

    let loggedIn = store.getters['user/loggedIn'];
    if( loggedIn === undefined && (to.meta.requiresAuth || to.meta.checkAuth) ) {
        let isAuthed = await authPromise;

        if( to.meta.requiresAuth && !isAuthed ) {
            next({
                name: 'Link Accounts'
            });
        }
    }

    next();
} )

export default router
