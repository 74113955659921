import authService from './auth.service';

export default class AxiosWrapper {
    axios;
    getAccessToken;

    constructor(axios) {
        this.axios = axios;
    }

    getAuthorizationHeader() {
        return {
            Authorization: authService.accessToken ? ("Bearer " + authService.accessToken) : undefined
        }
    }

    log(message) {
        if (process.env.NODE_ENV === "development") console.log('[Api Service] ' + message)
    }

    query(resource, params) {
        return this.axios.get(resource, {
            params,
            headers: this.getAuthorizationHeader()
        })
    }

    get(resource) {
        return this.axios.get(resource, { headers: this.getAuthorizationHeader() });
    }

    post(resource, params) {
        return this.axios.post(resource, params, { headers: this.getAuthorizationHeader() });
    }

    put(resource, params) {
        this.axios.put(resource, params, { headers: this.getAuthorizationHeader() });
    }

    patch(resource, params) {
        this.axios.patch(resource, params, { headers: this.getAuthorizationHeader() });
    }

    delete(resource) {
        return this.axios.delete(resource, { headers: this.getAuthorizationHeader() });
    }
}