import authApiService from './auth-api.service';


class UserService {

    async fetchUserInfo() {
        try {
            let user = (await authApiService.get('profiles/@self')).data.data;
            return {
                username: user.personaName,
                steamId: user.steamId,
                avatar: user.avatarFull,
                discordId: (user.discord && user.discord.id) || '',
                discordName: (user.discord && user.discord.userName) || ''
            }
        } catch (err) {
            return false;
        }
    }
}

export default new UserService();